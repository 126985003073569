<template>
  <v-container>
    <div class="ml-2"><PageTitle text="Drivers" class="ft" /></div>
    <div class="buttonText ml-1" id="result">
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="primary"
        class="ml-2 mt-3 white--text ft"
        style="font-size: 12px"
        @click="open('add')"
      >
        Add New Driver<v-icon size="20" color="white">mdi-plus</v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :loading="pageLoading"
            @click="refreshPage"
            icon
            v-on="on"
            class="mt-2 ml-2"
          >
            <i class="material-icons-outlined">refresh</i>
          </v-btn>
        </template>
        <span class=" ft font-weight-medium font-size-sm">Refresh Page</span>
      </v-tooltip>

      <!--      <button class="button ml-2 mt-6" @click.stop="create">-->
      <!--        New <v-icon size="20" color="white">mdi-plus</v-icon>-->
      <!--      </button>-->

      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="blue-grey--text ft font-size-sm"
      >
        List of Drivers
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="drivers"
              item-key="product"
              loading-text="Loading... Please wait"
              :loading="pageLoading"
              class=" box-shadow-light  ft font-weight-medium font-size-md"
            >
              <template #item.avatar="{item}">
                <v-avatar color="primary" style="margin-right: -80px" size="40">
                  <span class="white--text ft subtitle-1 font-weight-bold">{{
                    item.name.charAt(0)
                  }}</span>
                </v-avatar>
              </template>

              <template #item.createdAt="{item}">
                <span class="ft font-weight-medium">{{
                  item.createdAt | timestamp("DD/MM/YYYY")
                }}</span>
              </template>
              <template #item.driverAssignCarId="{item}">
                <v-chip
                  v-if="item.driverAssignCarId"
                  label
                  :color="item.driverAssignCarId ? '#9efb9e66' : '#f98c6b'"
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.driverAssignCarId
                        ? 'rgba(3, 91, 3, 0.83)'
                        : '#862c00'
                    }"
                    class="font-weight-bold"
                    >Assigned</span
                  >
                </v-chip>
                <v-chip
                  v-else
                  label
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span class="font-weight-bold">{{ "Not Assigned " }}</span>
                </v-chip>
              </template>
              <template #item.carId="{item}">
                <v-chip
                  v-if="item.driverAssignCarId"
                  label
                  :color="item.driverAssignCarId ? '#9efb9e66' : '#f98c6b'"
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    v-if="item.driverAssignCarId.carId"
                    :style="{
                      color: item.driverAssignCarId
                        ? 'rgba(3, 91, 3, 0.83)'
                        : '#862c00'
                    }"
                    class="font-weight-bold"
                    >{{ item.driverAssignCarId.carId.registrationNumber }} ({{
                      item.driverAssignCarId.carId.model
                    }})</span
                  >
                </v-chip>
                <v-chip
                  v-else
                  label
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span class="font-weight-bold">{{ "Not Assigned " }}</span>
                </v-chip>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="unassignCar(item)"
                      color="primary"
                      small
                      :loading="loading"
                      :disabled="loading || !item.driverAssignCarId"
                      v-on="on"
                      icon
                    >
                      <i class="material-icons-outlined font-size-md"
                        >person_remove</i
                      >
                    </v-btn>
                  </template>
                  <span class="font-weight-semi-bold ft font-size-sm "
                    >Unassign Driver From Car</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="open('edit', 'transport/driverDetails', item.id)"
                      small
                      color="primary"
                      v-on="on"
                      icon
                    >
                      <i class="material-icons font-size-md">edit</i>
                    </v-btn>
                  </template>
                  <span class="font-weight-semi-bold ft font-size-md "
                    >Edit Driver</span
                  >
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col>
    </v-row>
    <div v-if="managers.length > 0" class="buttonText ml-1" id="result">
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="primary"
        class="ml-2 mt-3 white--text ft"
        style="font-size: 12px"
        @click="open('details')"
      >
        Add New Transport Manager<v-icon size="20" color="white"
          >mdi-plus</v-icon
        >
      </v-btn>

      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="blue-grey--text ft font-size-sm"
      >
        Transport Managers
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div
            class="d-flex flex-column flex-grow-1"
            v-if="managers.length > 0"
          >
            <v-data-table
              :headers="transportHeaders"
              :items="managers"
              item-key="product"
              loading-text="Loading... Please wait"
              :loading="pageLoading"
              :server-items-length="paginate.total"
              :options="options"
              :footer-props="{
                itemsPerPageOptions: [10, 20, 30]
              }"
              class=" box-shadow-light  ft font-weight-medium font-size-md"
            >
              <template #item.avatar="{item}">
                <v-avatar color="primary" style="margin-right: -80px" size="40">
                  <span class="white--text ft subtitle-1 font-weight-bold">{{
                    item.name.charAt(0)
                  }}</span>
                </v-avatar>
              </template>

              <template #item.createdAt="{item}">
                <span class="ft font-weight-medium">{{
                  item.createdAt | timestamp("DD/MM/YYYY")
                }}</span>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="open('edit', 'transport/driverDetails', item.id)"
                      small
                      v-on="on"
                      icon
                    >
                      <i class="material-icons font-size-md">edit</i>
                    </v-btn>
                  </template>
                  <span class="font-weight-bold font-size-md "
                    >Edit Driver</span
                  >
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
          <div
            v-else
            class="d-flex flex-column flex-grow-1 grey lighten-4 mt-3"
          >
            <EmptyPage
              title="No transport manager added yet !"
              :subTitle="'All added transport managers will show up here.'"
              :image="require(`@/assets/folder.svg`)"
              :imageWidth="'120px'"
            >
              <template #action>
                <v-btn
                  v-ripple="{ class: 'primary--text' }"
                  color="primary"
                  class="ml-2 mt-3 white--text ft"
                  style="font-size: 12px"
                  @click="open('details')"
                >
                  Add New Transport Manager<v-icon size="20" color="white"
                    >mdi-plus</v-icon
                  >
                </v-btn>
              </template>
            </EmptyPage>
          </div>
        </template>
      </v-col>
    </v-row>
    <AddTransportManagerAdminDialog
      :state="dialog('details')"
      @addManager="addTransportManager"
      :loading="loading"
      @actions="close"
    />
    <AddDriverAndAssignCar
      :state="dialog('add')"
      @actions="close"
      :loading="loading"
      @driver="addNewDriver"
    />
    <EditDriverDialog
      :cars="cars"
      :state="dialog('edit')"
      :loading="loading"
      @editDriver="editDriver"
      @actions="close"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { timestamp } from "../../../filters/timestamp";
import DialogMixins from "../../../mixins/DialogMixins";
export default {
  name: "List",
  data() {
    return {
      headers: [
        { text: "", value: "avatar" },
        { text: "Name", value: "name" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Assigned Status", value: "driverAssignCarId" },
        { text: "Truck Assigned", value: "carId" },
        { text: "Actions", value: "actions" }
      ],
      transportHeaders: [
        { text: "", value: "avatar" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "mobile" }
      ],
      dialogState: {
        add: false,
        edit: false,
        delete: false
      },
      options: {}
    };
  },
  mixins: [DialogMixins],
  filters: {
    timestamp
  },
  computed: {
    ...mapGetters({
      showSnackBar: "getSnackBarState",
      message: "getSnackBarMessage",
      status: "getSnackBarStatus",
      loading: "getIsLoading",
      pageLoading: "getIsPageLoading",
      drivers: "transport/getAllDrivers",
      cars: "transport/getAllCars",
      managers: "transport/getTransportManagers",
      paginate: "transport/getPagination"
    })
  },
  watch: {
    options(value) {
      this.$store.dispatch("transport/listTransportManagers", {
        page: value.page,
        limit: value.itemsPerPage
      });
    }
  },
  components: {
    EmptyPage: () => import(`../../../components/EmptyPage`),
    PageTitle: () => import(`../../../components/PageTitle`),
    SnackBar: () => import(`../../../components/SnackBar`),
    AddDriverAndAssignCar: () =>
      import(`../../../views/pages/transport/dialogs/AddDriverAndAssignCar`),
    AddTransportManagerAdminDialog: () =>
      import(`./dialogs/AddTransportManagerAdminDialog`),
    EditDriverDialog: () => import(`./dialogs/EditDriverDialog`)
  },
  methods: {
    addNewDriver(payload) {
      this.$store.dispatch("transport/addDriver", payload);
    },
    editDriver(payload) {
      this.$store.dispatch("transport/editDriver", payload);
    },
    unassignCar(payload) {
      this.$store.dispatch("transport/unassignUserFromCar", payload);
    },
    addTransportManager(payload) {
      const role = this.$store.getters["branches/getDefaultBranchRole"]?.id;
      this.$store.dispatch("transport/addManager", { ...payload, role });
    },
    refreshPage() {
      this.$store.dispatch("transport/listOfDrivers");
      this.$store.dispatch("transport/listTransportManagers", {
        page: this.paginate.page,
        limit: this.paginate.limit
      });
    }
  },
  created() {
    this.$store.dispatch("transport/listOfDrivers");
    this.$store.dispatch("transport/listOfCars");
    this.$store.dispatch("branches/defaultRole", "transport");
    this.$store.dispatch("transport/listTransportManagers", {
      page: this.paginate.page,
      limit: this.paginate.limit
    });
  }
};
</script>
