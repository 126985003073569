var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"ft",attrs:{"text":"Drivers"}})],1),_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"ml-2 mt-3 white--text ft",staticStyle:{"font-size":"12px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.open('add')}}},[_vm._v(" Add New Driver"),_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-plus")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 ml-2",attrs:{"loading":_vm.pageLoading,"icon":""},on:{"click":_vm.refreshPage}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:" ft font-weight-medium font-size-sm"},[_vm._v("Refresh Page")])]),_c('h4',{staticClass:"blue-grey--text ft font-size-sm",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" List of Drivers ")])],1),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:" box-shadow-light  ft font-weight-medium font-size-md",attrs:{"headers":_vm.headers,"items":_vm.drivers,"item-key":"product","loading-text":"Loading... Please wait","loading":_vm.pageLoading},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-right":"-80px"},attrs:{"color":"primary","size":"40"}},[_c('span',{staticClass:"white--text ft subtitle-1 font-weight-bold"},[_vm._v(_vm._s(item.name.charAt(0)))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(_vm._f("timestamp")(item.createdAt,"DD/MM/YYYY")))])]}},{key:"item.driverAssignCarId",fn:function(ref){
var item = ref.item;
return [(item.driverAssignCarId)?_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.driverAssignCarId ? '#9efb9e66' : '#f98c6b',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.driverAssignCarId
                      ? 'rgba(3, 91, 3, 0.83)'
                      : '#862c00'
                  })},[_vm._v("Assigned")])]):_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s("Not Assigned "))])])]}},{key:"item.carId",fn:function(ref){
                  var item = ref.item;
return [(item.driverAssignCarId)?_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.driverAssignCarId ? '#9efb9e66' : '#f98c6b',"small":""}},[(item.driverAssignCarId.carId)?_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.driverAssignCarId
                      ? 'rgba(3, 91, 3, 0.83)'
                      : '#862c00'
                  })},[_vm._v(_vm._s(item.driverAssignCarId.carId.registrationNumber)+" ("+_vm._s(item.driverAssignCarId.carId.model)+")")]):_vm._e()]):_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s("Not Assigned "))])])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","loading":_vm.loading,"disabled":_vm.loading || !item.driverAssignCarId,"icon":""},on:{"click":function($event){return _vm.unassignCar(item)}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md"},[_vm._v("person_remove")])])]}}],null,true)},[_c('span',{staticClass:"font-weight-semi-bold ft font-size-sm "},[_vm._v("Unassign Driver From Car")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.open('edit', 'transport/driverDetails', item.id)}}},on),[_c('i',{staticClass:"material-icons font-size-md"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:"font-weight-semi-bold ft font-size-md "},[_vm._v("Edit Driver")])])]}}])})],1)]],2)],1),(_vm.managers.length > 0)?_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"ml-2 mt-3 white--text ft",staticStyle:{"font-size":"12px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.open('details')}}},[_vm._v(" Add New Transport Manager"),_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-plus")])],1),_c('h4',{staticClass:"blue-grey--text ft font-size-sm",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" Transport Managers ")])],1):_vm._e(),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[(_vm.managers.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-data-table',{staticClass:" box-shadow-light  ft font-weight-medium font-size-md",attrs:{"headers":_vm.transportHeaders,"items":_vm.managers,"item-key":"product","loading-text":"Loading... Please wait","loading":_vm.pageLoading,"server-items-length":_vm.paginate.total,"options":_vm.options,"footer-props":{
              itemsPerPageOptions: [10, 20, 30]
            }},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-right":"-80px"},attrs:{"color":"primary","size":"40"}},[_c('span',{staticClass:"white--text ft subtitle-1 font-weight-bold"},[_vm._v(_vm._s(item.name.charAt(0)))])])]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(_vm._f("timestamp")(item.createdAt,"DD/MM/YYYY")))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.open('edit', 'transport/driverDetails', item.id)}}},on),[_c('i',{staticClass:"material-icons font-size-md"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:"font-weight-bold font-size-md "},[_vm._v("Edit Driver")])])]}}],null,false,952255082)})],1):_c('div',{staticClass:"d-flex flex-column flex-grow-1 grey lighten-4 mt-3"},[_c('EmptyPage',{attrs:{"title":"No transport manager added yet !","subTitle":'All added transport managers will show up here.',"image":require("@/assets/folder.svg"),"imageWidth":'120px'},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"ml-2 mt-3 white--text ft",staticStyle:{"font-size":"12px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.open('details')}}},[_vm._v(" Add New Transport Manager"),_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-plus")])],1)]},proxy:true}])})],1)]],2)],1),_c('AddTransportManagerAdminDialog',{attrs:{"state":_vm.dialog('details'),"loading":_vm.loading},on:{"addManager":_vm.addTransportManager,"actions":_vm.close}}),_c('AddDriverAndAssignCar',{attrs:{"state":_vm.dialog('add'),"loading":_vm.loading},on:{"actions":_vm.close,"driver":_vm.addNewDriver}}),_c('EditDriverDialog',{attrs:{"cars":_vm.cars,"state":_vm.dialog('edit'),"loading":_vm.loading},on:{"editDriver":_vm.editDriver,"actions":_vm.close}}),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }